<template>
	<div style="background-image: linear-gradient(rgb(108, 155, 251), rgb(196, 127, 239)); margin: 10px auto; max-width: 1410px; border-radius: 10px;width:100%;">
		<top-main v-if="!isMobileLayout" :games="shuffleGames" />
		<grid-all v-if="!isMobileLayout" :games="shuffleGames" />
		<mobile-banner v-if="isMobileLayout" :games="shuffleGames"></mobile-banner>
		<mobile-ad v-if="isMobileLayout"></mobile-ad>
		<mobile-games v-if="isMobileLayout" :games="shuffleGames"></mobile-games>
	</div>
</template>

<script>
	import TopMain from '@/components/home/top-main'
	import GridAll from '@/components/home/grid-all'
	import MobileBanner from '@/components/home/mobile-banner'
	import MobileAd from '@/components/home/mobile-ad'
	import MobileGames from '@/components/home/mobile-games'
	import {mapState} from 'vuex'
	export default {
		inject: ['app'],
		components: {
			TopMain,
			GridAll,
			MobileBanner,
			MobileAd,
			MobileGames
		},
		computed: {
			...mapState({
				games: state => state.games.games
			}),
			shuffleGames() {
				return this.games.sort(() => Math.random() - 0.5)
			},
			isMobileLayout() {
				return this.app.isMobileLayout
			}
		}
	}
</script>