<template>
	<div class="mo-gridss">
		<game-item v-for="game in shuffleGames" :key="game.handle" :game="game" :showTitle="false" style="height: 100%; width: 100%; box-shadow: none; border-radius: 4px; display: flex; border: none; padding: 0px;"></game-item>
	</div>
</template>

<script>
	import GameItem from '@/components/common/game-item'
	export default {
		props: {
			games: {
				type: Array,
				default: () => []
			}
		},
		components: {
			GameItem
		},
		computed: {
			shuffleGames() {
				return this.games.slice(-30)
			}
		}
	}
</script>

<style scoped>
	.mo-gridss {
	    padding: 10px;
	    display: grid;
	    grid-template-columns: repeat(auto-fill,minmax(31%,auto));
	    grid-auto-rows: auto;
	    grid-auto-flow: dense;
	    grid-gap: 10px;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center
	}
</style>