<template>
	<div class="mo-top-banner">
		<div class="xtx-carousel">
			<ul class="carousel-body">
				<li class="carousel-item" v-for="game,index in carouselItems" :key="game.handle" :class="{'fade': showIndex%carouselItems.length==index}">
					<game-item :game="game" style="width: 454px; height: 252px;" :showTitle="false"></game-item>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import GameItem from '@/components/common/game-item'
	export default {
		props: {
			games: {
				type: Array,
				default: () => []
			}
		},
		components: {
			GameItem
		},
		data() {
			return {
				showIndex: 0
			}
		},
		computed: {
			carouselItems() {
				return this.games.slice(12,15)
			}
		},
		mounted() {
			setInterval(() => {
				this.showIndex++
			}, 2000)
		}
	}
</script>

<style scoped>
	.game-itemss {
	    display: inline-block;
	    cursor: pointer;
	    width: 100%;
	    overflow: hidden;
	    height: 87px;
	    border-radius: 4px
	}
	
	.game-false {
	    height: 130px
	}
	
	.gane-name {
	    overflow: hidden;
	    margin: .35714em;
	    color: #4a4a4a;
	    font-size: .875em;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    text-align: center;
	    font-family: Dosis
	}
	
	.game-item-div {
	    height: 122px;
	    width: 144px;
	    -webkit-transition: .2s;
	    transition: .2s;
	    border-radius: 10px;
	    overflow: hidden;
	    position: relative;
	    display: block;
	    padding: .125em;
	    border: 2px solid #fff;
	    border-radius: 5px;
	    background: #fff;
	    text-decoration: none;
	    -webkit-transition: box-shadow .45s cubic-bezier(.23,1,.32,1) 0ms;
	    transition: box-shadow .45s cubic-bezier(.23,1,.32,1) 0ms;
	    box-shadow: 0 1px 6px 0 rgba(50,50,50,.45);
	    margin: 0
	}
	
	.game-item-icon {
	    position: absolute;
	    height: 100%;
	    -o-object-fit: cover;
	    object-fit: cover
	}
	
	.game {
	    padding: 5px 10px 10px 10px;
	    -webkit-transition: .2s;
	    transition: .2s;
	    text-align: center;
	    color: #fff
	}
	
	.game-min {
	    padding: 2px 10px 3px 10px
	}
	
	.game-bottom {
	    font-weight: 500;
	    font-size: 13px;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    margin: 1px
	}
	
	.game-bottom-play {
	    font-size: 11px;
	    margin-top: 7px;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-justify-content: space-around;
	    justify-content: space-around
	}
	
	.game-bottom-favorites {
	    cursor: pointer
	}
	
	@media (max-width: 540px) {
	    .game-itemss {
	        height:106px;
	        padding-bottom: 100%
	    }
	
	    .game {
	        padding: 1px 10px 2px 10px
	    }
	}
	
	.mo-top-main-dialog {
	    position: fixed;
	    z-index: 99;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    background: #0000009c;
	    touch-action: none
	}
	
	.mo-top-main-wrap {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-orient: vertical;
	    -webkit-box-direction: normal;
	    -webkit-flex-direction: column;
	    -moz-box-orient: vertical;
	    -moz-box-direction: normal;
	    flex-direction: column;
	    height: 80%;
	    background: -webkit-gradient(linear,left top,left bottom,from(#8364da),to(#f4b7f9));
	    background: -webkit-linear-gradient(#8364da,#f4b7f9);
	    background: linear-gradient(#8364da,#f4b7f9)
	}
	
	.mo-top-main {
	    padding: 10px;
	    padding-top: 30px;
	    display: grid;
	    grid-template-columns: repeat(4,1fr);
	    grid-auto-rows: auto;
	    grid-auto-flow: dense;
	    grid-gap: 10px
	}
	
	.mo-top-main-item {
	    padding: .3125rem 0;
	    border-radius: .625rem;
	    box-shadow: 0 2px 10px 0 rgba(0,0,0,.25);
	    color: #936de4;
	    font-size: 10px;
	    background-color: #f2e0f8;
	    cursor: pointer;
	    width: 100%;
	    text-align: center
	}
	
	.mo-top-span {
	    padding: 0 .5rem;
	    white-space: nowrap;
	    overflow: hidden;
	    font-weight: 700;
	    display: block
	}
	
	.mo-top-bottom {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center;
	    padding: 10px;
	    padding-bottom: 20px
	}
	
	.mo-top-close {
	    width: 40px;
	    height: 40px;
	    padding: 10px;
	    border-radius: 9999px;
	    box-shadow: 0 2px 10px 0 rgba(0,0,0,.25);
	    background-color: #f2e0f8
	}
	
	.header-center-bottom[data-v-ad54570e] {
	    display: none;
	    color: #000;
	    position: absolute
	}
	
	.mo-fav-play[data-v-ad54570e] {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-justify-content: space-around;
	    justify-content: space-around;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -moz-box-align: center;
	    align-items: center
	}
	
	.mo-menu[data-v-ad54570e] {
	    background: #f2e0f8;
	    border: 1px solid #936de4;
	    padding: 2px;
	    border-radius: 9999px
	}
	
	.right {
	    font-size: 10px;
	    text-align: center
	}
	
	.right p {
	    margin: 0;
	    line-height: 1.5
	}
	
	.footer {
	    overflow: hidden;
	    padding: 10px;
	    color: #913bc6;
	    font-size: 9px;
	    text-align: center;
	    background-image: -webkit-gradient(linear,left top,left bottom,from(#ecb1f6),to(#8866db));
	    background-image: -webkit-linear-gradient(#ecb1f6,#8866db);
	    background-image: linear-gradient(#ecb1f6,#8866db);
	    bottom: 0;
	    width: 100%
	}
	
	.footer a {
	    margin: 0 8px;
	    line-height: 20px
	}
	
	.back-top {
	    position: fixed;
	    z-index: 4;
	    bottom: 86px;
	    right: 10px;
	    width: 40px;
	    height: 40px;
	    border-radius: 50%;
	    box-shadow: 2px 0 5px rgba(0,0,0,.3);
	    background: #c392eb url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAR+SURBVHgB7d1fqN9zHMfx1w9lhYSyCxQpqZVIFCVHUaJsIdLaLJpYRFj+RCaS0ZGsk5E1f+bfyAn5N6lzI25R7rhwYzduXO1i22evT99zsbO2nd+f75/P9/d+Purdp3XOWb/f5/0679/vfP+cIwEAAAAAgCgGy31CMqGvFgaDwXXH+4QThNAIQHAEIDgCEBwBCI4ABEcAgiMAwRGA4AhAcAQgOAIQHAEIjgAERwCCO2mIz9mipdcNpMV/Jx39eoJjffzw6wra/PhyXwcAAIJJKd3hWiHE48avTZWvXGcJcbjhG9JSP7nOE6afG70xHd0vrouF6eUGb0rH97vrcmH6uLGPpOH85bpGmB5u6OY0mn9dNwr950Y+ncbzv+s2ob/cwOfSZA641gn948a9mOpzv9AfbtjWVL/HhPK5Ua+l5jwrlMsN2paa97JQHjdme2rPNqEcbsiO1L4dQvfciPdTdz5xcXlZV7z5H6fufe06VWiPN/xE1+epHPl08kqhed7oFa4vU3l+dV0oNMcbfJrr21SuP1yXCPXzxp7p+jGV72/XVUJ9vKErXQupP/a6rlcPFH9rmDfyXC9fuK5Vf+Q3hPN+7GtUuKJ/hvUGXuDlU9cV6qd8G9q6wWDwoQpV7ARw8y9S9Z3f1+Zn+Rtsl5/LfSpUkQHwhq1S1fxLNR3e8nN6VAUqLgDeqNz0edcqTZfZVODp5KLeA3iD8rjf7Tpf02ur3xM8qUIUEwA3/2pVzT9H02/OIXhQBSgiAG7+jKp3+2crjp0OwT3qWOcBcPNvUNX8MxTPbofgTnWo0wC4+Tepan7k06nfuNY4CPvVgc5+CnDzV6v6US/6ufSbXXu8H6erA50EwE/2dlXNP1nI8h92yiFo/Q1w6wHwk7zLy2fiV9Qd6UrXD4tHQFvTahP85NZ7+Ug4lnzw63vv02VqSWsB8JO618t7wnLyCbAcglZuUW8lAKm6t+4dYVj5eMh3qYVb1BsPgJ/EQ17eFEZ1iitf/tboLeqNBmDxDNgbwrjycZp89fPdakhjAfCDfsLLrFCHd72fm9SARgLgB/uMF26irNec93WzalZ7APwgn/fygtCEV7y/W1SjWs8F+MG95OUpoWmzPnfwuGpQWwDc/Fe91PKgMJTtDsEDmlAtAXDzX/fysNC2DxyC9ZrAxAFw8+e8NPIOFUOZdwhu1ZgmCoCb/7aXjULX9rhWOwj7NKKxA+Dm7/SyQSjFz6pC8N8oXzRWANz8XV7WCqX5zXWLQ/DPsF8wcgDc/K4u3pxRv+TjIQdcB2uuYf7PvQ7Bn0M8xqH+bNwSXVzEuHhwaUY9470q/oBYX67K4RcxNaQvAeDysYYwAYJjAgTHBAiOCRAcAQiOl4DgmADBMQGCYwIExwQIjgkQHAEIjpeA4JgAwTEBgmMCBMcECI4JEBwTIDgmQHAEIDheAoJjAgTHBAiOCRAcEyA4JkBwBCA4XgKCYwIExwQIjgkQHBOgOUk9wAQIjgA0pxdTi5eA4JgAwY38ewI7kifAggAAAAAAADC2QzxH7I+G3TK/AAAAAElFTkSuQmCC) no-repeat 50%/20px
	}
	
	.xtx-carousel {
	    width: 100%;
	    min-width: 300px;
	    height: 314px;
	    position: relative;
	    @media (max-width: 540px) {
	        height:265px
	    }
	}
	
	.xtx-carousel:hover .carousel-btn {
	    opacity: 1
	}
	
	.carousel-body {
	    width: 100%;
	    height: 100%;
	    margin: 0
	}
	
	.carousel-body li {
	    list-style: none
	}
	
	.carousel-item {
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    left: 0;
	    top: 0;
	    opacity: 0;
	    -webkit-transition: opacity .5s linear;
	    transition: opacity .5s linear;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center
	}
	
	.fade {
	    opacity: 1;
	    z-index: 1
	}
	
	.mo-gridss {
	    padding: 10px;
	    display: grid;
	    grid-template-columns: repeat(auto-fill,minmax(31%,auto));
	    grid-auto-rows: auto;
	    grid-auto-flow: dense;
	    grid-gap: 10px;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center
	}
	
	.grid-all {
	    padding: 0 10px 10px 10px;
	    margin: 0 auto;
	    width: 1405px
	}
	
	.grid {
	    display: grid;
	    grid-template-columns: repeat(auto-fill,minmax(145px,0fr));
	    grid-auto-rows: auto;
	    grid-auto-flow: dense;
	    grid-gap: 10px;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center;
	    min-width: 1120px
	}
	
	.top-main {
	    width: 1405px;
	    margin: 0 auto 10px auto;
	    padding: 0 10px;
	    border-radius: 10px;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    height: 300px
	}
	
	.mo-top-span {
	    padding: 0 .5rem;
	    white-space: nowrap;
	    overflow: hidden;
	    width: 80px;
	    display: block
	}
	
	.top-main-left {
	    width: 455px;
	    height: 314px
	}
	
	.top-main-left-top {
	    display: inline-block;
	    margin-bottom: 8px;
	    padding: 0 20px;
	    border-radius: 9999px;
	    color: #fff;
	    line-height: 1.5;
	    font-family: Dosis;
	    font-weight: 600;
	    margin-top: 8px;
	    font-size: 20px
	}
	
	.top-main-left-main {
	    display: grid;
	    grid-template-columns: repeat(auto-fill,minmax(145px,0fr));
	    grid-auto-rows: auto;
	    grid-auto-flow: dense;
	    grid-gap: 10px
	}
	
	.top-main-right {
	    width: 455px;
	    height: 314px
	}
	
	.ads-class {
	    height: 250px;
	    grid-column: 4/6;
	    grid-row: 1/3;
	    overflow: hidden
	}
	
	.ads-class2 {
	    height: 250px;
	    grid-column: 7/9;
	    grid-row: 4/6;
	    overflow: hidden
	}
	
	.mo-top-banner {
	    overflow: hidden;
	    margin: 10px
	}
	
	.mo-top-banner img {
	    width: 100%;
	    -o-object-fit: cover;
	    object-fit: cover;
	    -o-object-position: top;
	    object-position: top
	}
	
	.mo-ad1 {
	    min-height: 250px;
	    margin-bottom: 10px
	}
	
	.mo-ad1,.mo-ad2 {
	    background: -webkit-gradient(linear,left top,left bottom,from(#6c9bfb),to(#8f85ee));
	    background: -webkit-linear-gradient(#6c9bfb,#8f85ee);
	    background: linear-gradient(#6c9bfb,#8f85ee)
	}
	
	.mo-ad2 {
	    height: 250px;
	    overflow: hidden
	}
	
	.load-more {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -moz-box-align: center;
	    align-items: center;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center;
	    border-radius: 10px;
	    border: 2px solid #fff;
	    padding: 8px;
	    color: #fff;
	    background-image: -webkit-gradient(linear,left top,left bottom,from(#f4e3f7),to(#cd82df));
	    background-image: -webkit-linear-gradient(#f4e3f7,#cd82df);
	    background-image: linear-gradient(#f4e3f7,#cd82df);
	    width: 300px;
	    height: 50px;
	    margin: 0 auto;
	    font-size: 20px;
	    font-weight: 700;
	    cursor: pointer;
	    -webkit-transition: all .6s ease;
	    transition: all .6s ease
	}
	
	.load-more:hover {
	    -webkit-transform: scale(1.2);
	    transform: scale(1.2);
	    background-color: #d50000
	}
	
	.bottom-category {
	    width: 1405px
	}
	
	.load-more-class {
	    width: 1405px;
	    border-radius: 0 0 10px 10px;
	    margin: 0 auto;
	    height: 100px;
	    padding-top: 10px
	}
	
	@media (max-width: 540px) {
	    .load-more:hover {
	        -webkit-transition:none;
	        transition: none;
	        -webkit-transform: none;
	        transform: none
	    }
	
	    .load-more-class {
	        width: auto;
	        margin: 0;
	        border-radius: 0
	    }
	}
	
	@media (max-width: 425px) {
	    .bottom-category {
	        width:450px
	    }
	}
	
	@media (max-width: 540px) {
	    .bottom-category {
	        grid-template-columns:repeat(2,auto);
	        width: 450px
	    }
	}
	
	@media (max-width: 450px) {
	    .bottom-category {
	        grid-template-columns:repeat(2,auto);
	        width: 330px
	    }
	}
	
	@media (max-width: 340px) {
	    .bottom-category {
	        grid-template-columns:repeat(2,auto);
	        width: 260px
	    }
	}

</style>